import React from "react";
import { Link } from "gatsby";
import { formatDate } from "../../../helpers/format";

const Info = ({ lotteryLogoSrc, lotteryName, country, date, isLinkToSinglePageActive, drawId }) => (
    <div className="lotteries-results__info-wrapper">
        {
            isLinkToSinglePageActive ?
                <Link className="lotteries-results__logo" to={`/results/${drawId}/`}>
                    {
                        lotteryLogoSrc ?
                            <img src={lotteryLogoSrc} alt={lotteryName}/>
                            :
                            <h4>No logo.</h4>
                    }
                </Link>
                :
                <div className="lotteries-results__logo">
                    {
                        lotteryLogoSrc ?
                            <img src={lotteryLogoSrc} alt={lotteryName}/>
                            :
                            <h4>No logo.</h4>
                    }
                </div>
        }
        <div className="lotteries-results__info">
            { country && <span className="lotteries-results__country">{country}</span>}
            {
                isLinkToSinglePageActive ?
                    <Link className="lotteries-results__name" to={`/results/${drawId}/`}>{lotteryName}</Link>
                    :
                    <span className="lotteries-results__name">{lotteryName}</span>
            }
            { date && <span className="lotteries-results__date">{formatDate(date)}</span>}
        </div>
    </div>
);

export default Info;
