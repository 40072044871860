import React from 'react';
import { Link } from "gatsby";
import Info from "../Info";
import Results from "../Results";
import lotteryLogos from "../../../helpers/lotteries";

const Row = ({
    lottery,
    countryDisable = false,
    isLinkToSinglePageActive = false
}) => {
    const lottaryNameCountryRemove = (country, name) => {
        const re = new RegExp(`${country} - `, 'i');
        return name.replace(re, '').toLowerCase();
    };

    const getLotteryLogo = drawId => {
        if ( lotteryLogos.hasOwnProperty(drawId) ) {
            return lotteryLogos[drawId];
        }
        return null;
    };

    return (
        <div className={`lotteries-results__row`}>
            <Info
                date={lottery?.draw_date}
                country={countryDisable ? false : lottery?.country}
                lotteryLogoSrc={getLotteryLogo(lottery?.draw_id)}
                lotteryName={lottery?.draw_name && lottaryNameCountryRemove(lottery.country, lottery.draw_name) }
                isLinkToSinglePageActive={isLinkToSinglePageActive}
                drawId={lottery?.draw_id}
            />
            <Results results={lottery?.results} />
            {
                isLinkToSinglePageActive &&
                <Link
                    to={`/results/${lottery?.draw_id}/`}
                    className='lotteries-results__load-more'
                >
                    More results
                </Link>
            }
        </div>
    );
};

export default Row;
